// 返回一些公共数据方法
import { storeToRefs } from "pinia";
import { useHot } from "/@/kernel-mn/stores/useHot";
import { useGameStore } from "/@/kernel-mn/stores/game";
import { useHaxiStore } from "/@/kernel-mn/stores/haxi";
import config from "/@/kernel-mn/config";
import { useTenantStore } from "/@/kernel-mn/stores/tenant";
import i18n, { $t2en } from "/@/languages";
import { determineType, getQPDZImg } from "/@/plugins/game";
import { exlotteryimg, lotteryImg, tResLoc } from "/@/plugins/global";

// 获取371130设置的图片 通过 message_id
export const mb_getLogoById = (id = 0): string => {
  const { hotData: { tenantInfo } } = useHot();
  // @ts-ignore
  let logo_arr = tenantInfo.value.logo_manage || [];
  if (logo_arr.length > 0) {
    let logos = logo_arr.find(items => items.message_id === id);
    return logos?.pic_addr ?? '';
  }
  return '';
};
// 判断字符串是否为链接
export const checkIsLinks = str => {
  return /^(https|http|ftp|rtsp|mms):\/\//.test(str);
};
// 获取用户头像 返回 原始head 和 组装的headimg地址 默认headurl登录的头像地址
export const getUserImg = (headurl = '') => {
  let { hotData: { userInfo }, imgDomain } = useHot();
  let img = '', head = headurl || userInfo.value.head;
  if (head) {
    img = !checkIsLinks(head) ? imgDomain.value + head : head;
  } else {
    img = '/images/mine/avatar.png';
  }
  return { img, head, imgDomain };
};
// 获取开启的导航菜单 type 1:棋牌,2:彩票,3:电子,4:捕鱼,5:真人,6:体育,7:红包,8:区块[原创],9:电竞
export const WEB_Menu = () => {
  return [
    { id: 1, title: '棋牌', key: 'chess', sort_v: 0, type: 1 },
    { id: 3, title: '电子', key: 'electronic', sort_v: 0, type: 3 },
    { id: 4, title: '捕鱼', key: 'fishing', sort_v: 0, type: 4 },
    { id: 2, title: '真人', key: 'live', sort_v: 0, type: 5 },
    { id: 5, title: '体育', key: 'sports', sort_v: 0, type: 6 },
    { id: 0, title: '彩票', key: 'lottery', sort_v: 0, type: 2 },
    { id: 6, title: '原创', key: 'block', sort_v: 0, type: 8 },
  ];
};
// sort_v = 0表示关闭此导航 isorg为真表示返回原始后台类型[WEB_Menu]的菜单类型
export const GET_WEB_Menu = (isorg = false) => {
  let newMenu = [{ id: 999, title: 'PC房间', key: 'lotterypc28', sort_v: 999, type: 999 }];
  if (isorg) newMenu = [];
  const gameStore = useGameStore();
  const { menuSort } = storeToRefs(gameStore);
  const local_menu = WEB_Menu();
  menuSort.value.forEach(item => {
    let menu = local_menu.find(val => val.type === item.type);
    menu && (menu.sort_v = item.sort_v);
    menu && menu.sort_v !== 0 && newMenu.push(menu);
  });
  // 接口返回已经排过了
  // newMenu.sort((pre, next) => next.sort_v - pre.sort_v);
  return newMenu;
};
// 返回平台设置的下载URL 安卓和IOS
export const getDownUrl = () => {
  const { hotData: { tenantInfo } } = useHot();
  const tenant: any = tenantInfo.value.tenant;
  return {
    android_url: tenant.official_addr,
    ios_url: tenant.app_reg_addr
  };
};
// 返回平台设置的联系方式 电话 qq 微信等
export const getContacList = () => {
  const { hotData: { tenantInfo } } = useHot();
  const tenant: any = tenantInfo.value.tenant;
  let contac = {
    email: tenant.email,
    facebook: tenant.facebook,
    telegram: (tenant.telegram || '').split('|'),
    whatsApp: tenant.whatsApp,
    phone: (tenant.phone || '').split('|')[0],
    qq: (tenant.qq || '').split('|')[0],
    weixin: (tenant.weixin || '').split('|')[0],
    zalo: (tenant.zalo || '').split(',')[0]
  };
  return contac;
};

// 返回大类游戏列表 通过类型 WEB_Menu的key
export const getBigGameListByType = (key = '', len = undefined) => {
  const gameStore = useGameStore();
  const haxiStore = useHaxiStore();
  // 原创游戏
  if (key === 'block') {
    const _data = [...haxiStore.gameList, ...gameStore.originalGames]
    _data.forEach(item => {
      item.play_cname = item.isOriginal ? item.showName : item.play?.showname;
      item.play_name = item.isOriginal ? item.name : item.play?.name;
      item.img_url = item.logoUrl || `/images/gameAll/qk/${(item.isOriginal ? item.name : item.play.name)}.png`
    });
    return _data.slice(0, len);
  }
  // 彩票
  if (key === 'lottery') {
    const newData = gameStore.hallDataAll.sort((a, b) => {
      const topDiff = b.is_top - a.is_top
      const hotDiff = b.hot_val - a.hot_val
      if (topDiff === 0) { // 一样的话再根据 hot_val 排列
        if (hotDiff === 0) { // 一样的话再根据 sub_play_id 排列 第三方彩sub_play_id为0，所以使用play_id
          return (b.sub_play_id || b.play_id) - (a.sub_play_id || a.play_id)
        } else {
          return hotDiff
        }
      } else {
        return topDiff
      }
    });
    return newData.slice(0, len);
  }
  // PC28 暂时写死
  if (key === 'lotterypc28') {
    let _data = gameStore.savePC28.slice(0, len)
    // 数据过滤
    // _data = _data.filter(v => ["JND00", "TWBINGO", "JNDX00", "BTB128"].includes(v.play_name)).sort((a, b) => a.hot_val > b.hot_val ? -1 : 1)
    _data = _data.sort((a, b) => a.hot_val > b.hot_val ? -1 : 1)
    const result = []
    // 只显示加拿大/比特币/台湾宾果/加拿大西
    _data.forEach(item => {
      // 34129 紧急更新-602-mn：之前幸运28不让显示在首页，现在针对于602搞显示出来下
      // if (![3, 9].includes(item.play_state) && [1400, 1401, 41, 387].includes(item.play_id)) {
      //   result.push({
      //     ...item,
      //     img_url: `/images/${tResLoc().value}/lotterypc28/${item.play_id}.png`
      //   })
      // }
      if (![3, 9].includes(item.play_state)) {
        result.push({
          ...item,
          img_url: `/images/${tResLoc().value}/lotterypc28/${item.play_id}.png`
        })
      }
    })
    // console.log('lotterypc28: ', _data, result)
    return result;
  }
  // 棋牌 电子 捕鱼 真人 体育
  // 大类的图片获取本地图片
  let _data = (gameStore.gameBigClass[key]?.filter(item => item.state !== 3) || []).slice(0, len);
  _data = _data.map(v => {
    return {
      ...v,
      img_url: `/images/${tResLoc().value}/${key}/${v.play_id}.png`
    }
  })
  return _data;
};
// 返回彩票大类
export const getLotteryBigType = () => {
  const gameStore = useGameStore();
  const { tianChengList, menuSort, isShowGPIBtn, isShowSBCPBtn, isShowDBCPBtn } = storeToRefs(gameStore);
  let arr: any = [
    { play_cname: '全部彩种', type: 0, icon: 0 }, // icon: 小图标数字 跟排序type相关
    { play_cname: '热门彩种', type: 1, icon: 1 },
    { play_cname: 'PC28', type: 2, icon: 14 },
    { play_cname: '赛车', type: 3, icon: 13 },
    { play_cname: '时时彩', type: 4, icon: 15 },
    { play_cname: '11选5', type: 5, icon: 11 },
    { play_cname: '六合彩', type: 6, icon: 16 },
    { play_cname: '快3', type: 7, icon: 17 },
    { play_cname: '幸运飞艇', type: 8, icon: 12 },
    { play_cname: '境外彩', type: 10, icon: 18 },
    { play_cname: '印度彩', type: 12, icon: 27 },
    { play_cname: '原创', type: 18, icon: 31 },
    { play_cname: '福彩3D', type: 19, icon: 32 }
  ];
  if (tianChengList && tianChengList.value.length) {
    for (let i = 0; i < tianChengList.value.length; i++) {
      let tc: any = tianChengList.value[i];
      if (tc.games) {
        arr.push({ play_cname: tc.displayName || '', type: 20 + i, cname: tc.displayName || '' });
      }
    }
  }
  if (!isShowGPIBtn.value) {
    arr.push({ play_cname: 'GPI彩票', type: 17, cname: 'GPI彩票', icon: 26 });
  }
  if (!isShowSBCPBtn.value) {
    arr.push({ play_cname: '沙巴彩票', cname: '沙巴彩票', type: 30, icon: 37, play_id: 1326 });
  }

  if (!isShowDBCPBtn.value) {
    arr.push({ play_cname: 'DB彩票', cname: 'DB彩票', type: 38, icon: 37, play_id: 1385 });
  }
  let newArr = [
    { play_cname: '全部彩种', type: 0, icon: 0 },
    { play_cname: '热门彩种', type: 1, icon: 1 }
  ];
  if (menuSort.value && menuSort.value.length) {
    for (let i = 0; i < menuSort.value.length; i++) {
      let type = menuSort.value[i].type;
      let sort = Number(menuSort.value[i].sort_v);
      // 11: 11选5，12: 飞艇， 13: 赛车, 14: PC00， 15: 时时彩， 16: 六合彩， 17: 快三,18: 境外彩,
      // 19: 泰彩, 20: 越南彩, 21: 老挝彩, 22: 马来西亚彩, 23:新加坡彩: 24:台湾彩, 25: 股票彩, 26: GPI彩票
      // 27: 印度彩 31：原创 32：福彩3D
      // 上面这个type是 icon的值
      let current = arr.find(cp => cp.icon === type) || null; // 从arr 里面找到当前的这个 通过type
      switch (type) {
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
        case 17:
        case 18:
        case 27:
        case 31:
        case 32:
          if (sort !== 0 && current) newArr.push(current); // 非第三方彩
          break;
        case 19:
          if (sort !== 0 && tianChengList.value) {
            current = arr.find(cp => cp.cname === '泰彩') || null;
            current && newArr.push({ ...current, icon: type });
          }
          break;
        case 20:
          if (sort !== 0 && tianChengList.value) {
            current = arr.find(cp => cp.cname === '越南彩') || null;
            current && newArr.push({ ...current, icon: type });
          }
          break;
        case 21:
          if (sort !== 0 && tianChengList.value) {
            current = arr.find(cp => cp.cname === '老挝彩') || null;
            current && newArr.push({ ...current, icon: type });
          }
          break;
        case 22:
          if (sort !== 0 && tianChengList.value) {
            current = arr.find(cp => cp.cname === '马来西亚彩') || null;
            current && newArr.push({ ...current, icon: type });
          }
          break;
        case 23:
          if (sort !== 0 && tianChengList.value) {
            current = arr.find(cp => cp.cname === '新加坡彩') || null;
            current && newArr.push({ ...current, icon: type });
          }
          break;
        case 24:
          if (sort !== 0 && tianChengList.value) {
            current = arr.find(cp => cp.cname === '台湾彩') || null;
            current && newArr.push({ ...current, icon: type });
          }
          break;
        case 25:
          if (sort !== 0 && tianChengList.value) {
            current = arr.find(cp => cp.cname === '股票彩') || null;
            current && newArr.push({ ...current, icon: type });
          }
          break;
        case 26:
          if (sort !== 0 && !isShowGPIBtn.value) {
            current = arr.find(cp => cp.cname === 'GPI彩票') || null;
            current && newArr.push({ ...current, icon: type });
          }
          break;
        case 37:
          if (sort !== 0 && !isShowSBCPBtn.value) {
            current = arr.find(cp => cp.cname === '沙巴彩票') || null;
            current && newArr.push({ ...current, icon: type });
          }
          break;
        case 38:
          if (sort !== 0 && !isShowDBCPBtn.value) {
            current = arr.find(cp => cp.cname === 'DB彩票') || null;
            current && newArr.push({ ...current, icon: type });
          }
          break;
      }
    }
  }
  return newArr;
};
// 组装捕鱼 小类游戏
export const getFishItem = item => {
  const tenantState = useTenantStore();
  const { imgDomain } = storeToRefs(tenantState);
  const gameStore = useGameStore();
  const { playAllStates } = storeToRefs(gameStore);
  let key = Object.keys(item)[0];
  // if (key.length === 3) return null; // 过滤大类
  let play = config.getPlayInfo(key); // 获取捕鱼类型信息
  let mainId = key;
  if (play) {
    mainId = play.main_id || play.id || key;
    return {
      ...item,
      gameId: mainId,
      play_state: playAllStates.value[mainId]?.play.play_state || item[key],
      play_id: mainId || key, // 永远都是大类id
      play_type: play.type,
      title: play.play_cname,
      play_cname: play.play_cname,
      is_collect: item.is_collect || item.play_collection,
      subplayid: play.default === mainId ? undefined : play.default, // 大类游戏就没有小类id 用这个值区分大小类
      main_id: mainId,
      gameImgUrl: `${imgDomain.value}staticQ/buyu/${mainId}/${$t2en(i18n.global.locale)}/game_${key}.png`
    };
  }
  return null;
};
// 组装热门游戏等游戏数据 isbig 为真直接返回bigClass
export const getItemData = (item, isbig = false) => {
  let item_type = determineType(item.big_type || item.upper_play_id, isbig); // 游戏类型 qipai caipiao
  if (['crash', 'mine', 'dabble', 'dice', 'ring'].indexOf(item.name) !== -1) {
    item_type = 'qukuailian';
  }
  item.play_cname = item.play_cname.trim().replace(/[\u200B-\u200D\uFEFF]/g, '');
  // 15 体育  11 视讯  13捕鱼
  const gameCollectSeriesMap = {
    dianzi: 12,
    qipai: 17,
    caipai: 16
  };
  // 哈希游戏
  if (item.play) {
    item.type = 'hash';
    item.play_name = item.play.name;
    item.play_cname = item.play.showname;
    item.play_id = item.id;
    item.isOriginal = false;
  }
  // 原创
  if (item.showName) {
    item.type = 'hash';
    item.play_name = item.name;
    item.play_cname = item.showName;
    item.play_id = item.id;
    item.isOriginal = true;
  }
  return {
    ...item,
    playId: item.play_id,
    gameId: item.upper_play_id || item.play_id,
    gameKey: item.play_name,
    gameName: item.play_cname,
    isLotteryTicket: item_type === 'caipai',
    is_collect: item.is_collect || item.play_collection,
    gameImgUrl: item.img_url || getQPDZImg(item) || lotteryImg(item) || exlotteryimg(item),
    item_type, // 游戏类型 qipai caipiao
    gameCollectSeries: gameCollectSeriesMap[item_type] || 0
  };
};
