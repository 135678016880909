import { getSDK, CbEvents } from '@openim/wasm-client-sdk';
// import defaultAvatar from './defaultAvatar.js'
import { sendOpenImMessage } from "/@/hooks/chatRoomActions";

export const IMSDK = getSDK({
  coreWasmPath: "/openIm/openIM.wasm",
  sqlWasmPath: "/openIm/sql-wasm.wasm",
  debug: false, // false不打印日志
});

// 消息类型
export const OPENIM_TYPE = {
  TEXT: 101,
  IMAGE: 102,
  AUDIO: 103,
  VIDEO: 104,
  FILE: 105,
  AT: 106,
  LOCATION: 109,
  CUSTOM: 110,
  SYSTEM: 1400
}

export default {
  userInfo: null,
  joinRoom: null,
  context: null,

  async init(registerData, joinRoom, domains, chatRoomStore) {    
    this.context = chatRoomStore
    this.userInfo = registerData
    this.joinRoom = joinRoom.value

    try {
      const loginStatus = await IMSDK.getLoginStatus();
      if (loginStatus.data !== 3) {
        console.log("IMSDK.login:", {
          userID: this.userInfo.user_id,
          token: this.userInfo.im_ws_token,
          platformID: 5,
          apiAddr: domains.sdk_http[0],
          wsAddr: domains.ws[0],
        });
        await IMSDK.login({
          userID: this.userInfo.user_id,
          token: this.userInfo.im_ws_token,
          platformID: 5,
          apiAddr: domains.sdk_http[0],
          wsAddr: domains.ws[0],
        }).catch(e=>{
          console.log("e:", e);
        });
    
        IMSDK.on(CbEvents.OnConnecting, this.handleConnecting.bind(this));
        IMSDK.on(CbEvents.OnConnectSuccess, this.handleConnectSuccess.bind(this));
        IMSDK.on(CbEvents.OnConnectFailed, this.handleConnectFailed.bind(this));
        IMSDK.on(CbEvents.OnRecvNewMessages, this.handleNewMessages.bind(this));
      }

      console.log('openIm 登录成功')
    } catch (error) {
      console.log('openIm 登录失败', error)
    }
  },
  handleConnecting() {
    console.log('handleConnecting')
  },
  async handleConnectSuccess() {
    console.log('handleConnectSuccess')
    this.context.setOnline(true)
  },
  handleNewMessages({ data: messages }) {
    this.context.setMessage(messages, true)
  },
  handleConnectFailed({ errCode, errMsg }) {
    console.log(errCode, errMsg);
    this.context.setOnline(false)
  },
  async sendMessage(body, type, isMuc) {
    try {
      await sendOpenImMessage({
        user_id: this.userInfo.user_id,
        room_id: `${this.joinRoom.id}`,
        content: body,
        face_url: this.userInfo.face_url,
        nickname: this.userInfo.nickname,
        type,
        platform_id: 5
      })
    } catch (error) {
      console.log(error)
    }
  },
}