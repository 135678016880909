/*
 * @Author: castic
 * @Date: 2024-12-20 17:19:30
 * @LastEditors: castic
 * @LastEditTime: 2024-12-24 20:56:40
 * @Describe: 
 */
// 隐藏底部页面路由名称
export default [
  "Login",
  "Register",
  "MyNews",
  "Pk10",
  "Hklottery",
  "Cqlhc",
  "K3",
  "Roomchat",
  "xydzp",
  "Qkl",
  "Xnb",
  "Ydc",
  "Chart",
  "Chatroom",
  "Message",
  "SetUp",
  "Withdraw",
  "Personalreport",
  "BettingRecord",
  "TransactionRecord",
  "WalletManagement",
  "YuEbao",
  "InterestFreeBorrowing",
  "borrowRecord",
  "AccountTransaction",
  "SelfServiceReturnWater",
  "RedEnvelopeIncome",
  "LuckyNote",
  "RegisterPrizes",
  "BigTurntable",
  "PromoteMoney",
  "MyCommission",
  "mgAgentlist",
  "mgAgentrank",
  "mgAgentdetail",
  "ProxySystem",
  "mgropenaccount",
  "mgrurllist",
  "teamgames",
  "ProxyTutorial",
  "LongDragonAssistant",
  "SelfBetting",
  "MyChase",
  "Service",
  "MyCollection",
  "SpareWebsite",
  "HelpCenter",
  "userChatInfo",
  "resetpass",
  "ResetTxPass",
  "jumph5",
  "selectedbet",
  "iframe",
  "MyShare",
  "promotion",
  "agencyReport",
  "teamWithdraw",
  "teamPay",
  "DayaccountsList",
  "teamBalance",
  "MgrurlAdd",
  "mgrurlDetail",
  "profitlost",
  "mgrpromotionurl",
  "AccountDetail",
  "borrowPayback",
  "ZijinList",
  "OffinePay",
  "borrowQuota",
  "borrowLoan",
  "specialBenefits",
  "LongDragonAssistantSM",
  "selfBettingSM",
  "ChatroomSelect",
  "LotteryChoice",
  "BankCards",
  "PointsRecord",
  "mgAgentAdd",
  "AboutUs",
  "withdrawWays",
  "conversionAmount",
  "Vip",
  "VipDetail",
  "AgentShare",
  "BindPayAdd",
  'PC28',
  'codedetail',
  'subagent',
];