import network from '../../network';
import config from '/@/kernel-mn/config';
import * as utils from '/@/kernel-mn/utils';
import { useTenantStore } from '../tenant';
import { get, getJSON, setJSON } from '/@/kernel-mn/utils/storage';
import { useHot } from '/@/kernel-mn/stores/useHot';
import { getp, num2Li, isApp } from '../../utils';
import RNEvent from '/@/kernel-mn/utils/RNEvent';
import { useUserStore } from '/@/kernel-mn/stores/user/index';

export default {
  /**
   * @name 查询特殊校验状态
   * @param payload.cust_name 用户账号
   */
  async reqSpecialStatus(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_name: payload.cust_name?.trim()
    };
    try {
      const res = await network.request({
        cmdId: 40001,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 登录
   */
  async login(data) {
    const RNEventInstance = RNEvent.getInstance();
    const tenantState = useTenantStore();
    data = {
      ...data,
      chan_code: data.chan_code || config.chanCode,
      client_id: 'react_client',
      domain_addr: window.location.host,
      grant_type: 'password',
      password: utils.getp(data.password?.trim()),
      scope: 'read write',
      tid: tenantState.tenantInfo.tid,
      verifycode: data.verifycode,
      verifycode_key: this.verifyCode.verifycode_key,
      cust_name: data.cust_name
    };
    try {
      const res = await network.request({
        cmdId: 4,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        this.userInfo = res.data;
        if (isApp()) {
          RNEventInstance.writeRecord('userInfo', JSON.stringify(res.data));
        }
        setJSON('userInfo', res.data);
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  /**
   * 试玩登录
   * */ 
  async tryLogin(data = {}) {
    const RNEventInstance = RNEvent.getInstance();
    const tenantState = useTenantStore();
    data = {
      ...data,
      chan_code: config.chanCode,
      client_id: 'react_client',
      grant_type: 'password',
      domain_addr: window.location.host,
      scope: 'read write',
      tid: tenantState.tenantInfo.tid,
    };
    try {
      const res = await network.request({
        cmdId: 9,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        const fmData = { ...res.data, cust_name: '游客' }
        console.log(fmData)
        this.userInfo = fmData;
        if (isApp()) {
          RNEventInstance.writeRecord('userInfo', JSON.stringify(fmData));
        }
        setJSON('userInfo', fmData);
        return Promise.resolve(fmData);
      } else {
        return Promise.reject(res);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 账号注册
   */
  async register(payload) {
    const RNEventInstance = RNEvent.getInstance();
    const userStore = useUserStore();
    const tenantState = useTenantStore();
    // 头像的地址
    const headURI = '/static/heads/head_' + utils.strPadLeft(utils.randomInteger(1, 200), 2) + '.png';
    let data = {
      ...payload,
      acct_pwd: payload.acctPwd ? utils.getp(payload.acctPwd) : '',
      adm_code: '1',
      chan_code: payload.chan_code || config.chanCode,
      client_id: 'react_client',
      contact_name: payload.contact_name || '', // 真实会员名称
      cust_name: payload.cust_name, // 会员账号
      domain_addr: window.location.host,
      email: payload.email || '', // 注册邮箱
      facebook: payload.facebook || '', // 注册的facebook
      gender: 0,
      grant_type: 'password',
      head: headURI,
      invite_code: payload.inviteCode || payload.invite_code || '', // 邀请码
      line: payload.line || '', // line 邮箱
      link_addr: payload.link_addr || '',
      mobile_phone: payload.mobile_phone || '', // 联系电话
      nickname: payload.nickname || '', // 昵称
      telegram: payload.telegram || '',
      tiktok: payload.tiktok || '', // 抖音号
      weixin: payload.weixin || '', // 微信号
      password: payload.password ? utils.getp(payload.password.trim()) : '',
      platform: utils.getBetPlatform(),
      proxy: tenantState.tenantInfo.proxy,
      qq: payload.qq || '', // qq
      regist_from: utils.getHostName(tenantState.tenantInfo.domain_addr || ''),
      scope: 'read write',
      state: 1,
      tid: tenantState.tenantInfo.tid,
      whatsApp: payload.whatsApp || '',
      zalo: payload.zalo || '',
      verifycode: payload.verifycode,
      verifycode_key: this.verifyCode.verifycode_key,
      isQp: payload.isQp || 1
    };
    if (userStore.deviceUDID) {
      data.app_key = userStore.deviceUDID;
    }
    if (payload.validate) {
      data.validate = payload.validate;
    }
    try {
      const res = await network.request({
        cmdId: 3,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: true
      });
      if (res.type === 'success') {
        this.userInfo = res.data;
        if (isApp()) {
          RNEventInstance.writeRecord('userInfo', JSON.stringify(res.data));
        }
        setJSON('userInfo', res.data);
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 发送注册短信
   */
  async reqSMSRigister(payload) {
    const RNEventInstance = RNEvent.getInstance();
    const tenantState = useTenantStore();
    // 头像的地址
    const headURI = '/static/heads/head_' + utils.strPadLeft(utils.randomInteger(1, 200), 2) + '.png';
    let data = {
      ...payload,
      acct_pwd: payload.acctPwd ? utils.getp(payload.acctPwd) : '',
      adm_code: '1',
      chan_code: payload.chan_code || config.chanCode,
      client_id: 'react_client',
      contact_name: payload.contact_name || '', // 真实会员名称
      cust_name: payload.cust_name, // 会员账号
      domain_addr: window.location.host,
      email: payload.email || '', // 注册邮箱
      facebook: payload.facebook || '', // 注册的facebook
      gender: 0,
      grant_type: 'password',
      head: headURI,
      invite_code: payload.inviteCode || payload.invite_code || '', // 邀请码
      isQp: payload.isQp || 1,
      line: payload.line || '', // line 邮箱
      link_addr: payload.link_addr || '',
      mobile_phone: payload.mobile_phone || '', // 联系电话
      nickname: payload.nickname || '', // 昵称
      telegram: payload.telegram || '',
      tiktok: payload.tiktok || '', // 抖音号
      weixin: payload.weixin || '', // 微信号
      password: payload.password ? utils.getp(payload.password) : '',
      platform: utils.getBetPlatform(),
      proxy: tenantState.tenantInfo.proxy,
      qq: payload.qq || '', // qq
      regist_from: utils.getHostName(tenantState.tenantInfo.domain_addr || ''),
      scope: 'read write',
      smsCode: payload.smsCode,
      state: 1,
      tid: tenantState.tenantInfo.tid,
      whatsApp: payload.whatsApp || '',
      zalo: payload.zalo || '',
      verifycode: payload.verifycode,
      verifycode_key: this.verifyCode.verifycode_key
    };
    if (payload.validate) {
      data.validate = payload.validate;
    }
    if (payload.phone) {
      data.phone = payload.phone;
    }
    try {
      const res = await network.request({
        cmdId: 301,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: true
      });
      if (res.type === 'success') {
        this.userInfo = res.data;
        if (isApp()) {
          RNEventInstance.writeRecord('userInfo', JSON.stringify(res.data));
        }
        setJSON('userInfo', res.data);
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 邮箱注册
   */
  async reqEmailRigister(payload) {
    const RNEventInstance = RNEvent.getInstance();
    const tenantState = useTenantStore();
    // 头像的地址
    const headURI = '/static/heads/head_' + utils.strPadLeft(utils.randomInteger(1, 200), 2) + '.png';
    let data = {
      ...payload,
      acct_pwd: payload.acctPwd ? utils.getp(payload.acctPwd) : '',
      adm_code: '1',
      chan_code: payload.chan_code || config.chanCode,
      client_id: 'react_client',
      contact_name: payload.contact_name || '', // 真实会员名称
      cust_name: payload.cust_name, // 会员账号
      domain_addr: window.location.host,
      email: payload.email || '', // 注册邮箱
      smsCode: payload.emailCode || '',
      facebook: payload.facebook || '', // 注册的facebook
      gender: 0,
      grant_type: 'password',
      head: headURI,
      invite_code: payload.inviteCode || payload.invite_code || '', // 邀请码
      isQp: payload.isQp || 1,
      line: payload.line || '', // line 邮箱
      link_addr: payload.link_addr || '',
      mobile_phone: payload.mobile_phone || '', // 联系电话
      nickname: payload.nickname || '', // 昵称
      telegram: payload.telegram || '',
      tiktok: payload.tiktok || '', // 抖音号
      weixin: payload.weixin || '', // 微信号
      password: payload.password ? utils.getp(payload.password.trim()) : '',
      platform: utils.getBetPlatform(),
      proxy: tenantState.tenantInfo.proxy,
      qq: payload.qq || '', // qq
      regist_from: utils.getHostName(tenantState.tenantInfo.domain_addr || ''),
      scope: 'read write',
      state: 1,
      tid: tenantState.tenantInfo.tid,
      whatsApp: payload.whatsApp || '',
      zalo: payload.zalo || '',
      verifycode: payload.verifycode,
      verifycode_key: this.verifyCode.verifycode_key
    };
    if (payload.validate) {
      data.validate = payload.validate;
    }
    try {
      const res = await network.request({
        cmdId: 302,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: true
      });
      if (res.type === 'success') {
        this.userInfo = res.data;
        if (isApp()) {
          RNEventInstance.writeRecord('userInfo', JSON.stringify(res.data));
        }
        setJSON('userInfo', res.data);
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取图形验证码
   */
  async fetchVerifyCode(data) {
    data = {
      ...data,
      verifycode_key: utils.uuid(32, 16),
      type: data.type || 1
    };
    try {
      const res = await network.request({
        cmdId: 7,
        data: data,
        headerType: 1
      });
      if (res && res.type === 'success') {
        this.verifyCode = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 发送邮箱验证码
   */
  async reqEmailCode(data): Promise<any> {
    const tenantState = useTenantStore();
    data = {
      ...data,
      tid: tenantState.tenantInfo.tid,
      verifycode: data.verifycode,
      verifycode_key: this.verifyCode.verifycode_key,
      client_id: 'react_client',
      grant_type: 'password',
      scope: 'read write',
      email_type: data.email_type,
      email_to_account: data.email_to_account
    };
    try {
      const res = await network.request({
        cmdId: 100,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 发送手机验证码
   */
  async reqSMSCode(data): Promise<any> {
    const tenantState = useTenantStore();
    data = {
      ...data,
      tid: tenantState.tenantInfo.tid,
      verifycode: data.verifycode,
      verifycode_key: this.verifyCode.verifycode_key,
      client_id: 'react_client',
      grant_type: 'password',
      scope: 'read write',
      type: data.type,
      country: data.country || 'India',
      receive_mobile: data.receive_mobile
    };
    try {
      const res = await network.request({
        cmdId: 10,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 短信登录
   */
  async reqSMSlogin(data): Promise<any> {
    const RNEventInstance = RNEvent.getInstance();
    const tenantState = useTenantStore();
    data = {
      ...data,
      chan_code: data.chan_code || config.chanCode,
      client_id: 'react_client',
      cust_name: data.receiveMobile,
      domain_addr: window.location.host,
      grant_type: 'password',
      password: '',
      scope: 'read write',
      smsCode: data.smsCode,
      tid: tenantState.tenantInfo.tid,
      verifycode: data.verifycode,
      verifycode_key: this.verifyCode.verifycode_key
    };
    try {
      const res = await network.request({
        cmdId: 401,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        this.userInfo = res.data;
        if (isApp()) {
          RNEventInstance.writeRecord('userInfo', JSON.stringify(res.data));
        }
        setJSON('userInfo', res.data);
      } else {
        return Promise.reject(res);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 邮箱密码登录
   */
  async reqEmailPwdlogin(data): Promise<any> {
    const RNEventInstance = RNEvent.getInstance();
    const tenantState = useTenantStore();
    data = {
      ...data,
      chan_code: data.chan_code || config.chanCode,
      client_id: 'react_client',
      cust_name: data.emailName,
      domain_addr: window.location.host,
      grant_type: 'password',
      password: utils.getp(data.password.trim()),
      scope: 'read write',
      tid: tenantState.tenantInfo.tid,
      verifycode: data.verifycode,
      verifycode_key: this.verifyCode.verifycode_key
    };
    try {
      const res = await network.request({
        cmdId: 403,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        this.userInfo = res.data;
        if (isApp()) {
          RNEventInstance.writeRecord('userInfo', JSON.stringify(res.data));
        }
        setJSON('userInfo', res.data);
      } else {
        return Promise.reject(res);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 邮箱登录
   */
  async reqEmaillogin(data): Promise<any> {
    const RNEventInstance = RNEvent.getInstance();
    const tenantState = useTenantStore();
    data = {
      ...data,
      chan_code: data.chan_code || config.chanCode,
      client_id: 'react_client',
      cust_name: data.emailName,
      domain_addr: window.location.host,
      grant_type: 'password',
      password: '',
      scope: 'read write',
      smsCode: data.emailCode,
      tid: tenantState.tenantInfo.tid,
      verifycode: data.verifycode,
      verifycode_key: this.verifyCode.verifycode_key
    };
    try {
      const res = await network.request({
        cmdId: 402,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        this.userInfo = res.data;
        if (isApp()) {
          RNEventInstance.writeRecord('userInfo', JSON.stringify(res.data));
        }
        setJSON('userInfo', res.data);
      } else {
        return Promise.reject(res);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 重置密码
   */
  async reqResetPwd(payload): Promise<any> {
    const tenantState = useTenantStore();
    const data = {
      tid: tenantState.tenantInfo.tid,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 31011102,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 查询用户信息认证状态
   * */
  async reqCertificationInfo(payload = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 325308,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        this.certification = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 提交认证信息
   * contact_name:姓名 id_card_log_addr:证件图片地址 id_card:证件号码 country:国家 document_type:证件类型
   * */
  async reqIDCertification(payload = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 325310,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 提交邮箱认证
   * contact_name:姓名 email:邮箱 yzm验证码
   * */
  async reqEmailCertification(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      contact_name: payload.contact_name,
      email: payload.email,
      yzm: payload.yzm
    };
    try {
      const res = await network.request({
        cmdId: 325309,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 返回用户钱包地址
   * contact_name:姓名 id_card_log_addr:证件图片地址 id_card:证件号码 country:国家 document_type:证件类型
   * */
  async reqBindBankList(payload = []) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = [tenantInfo.value.tid, userInfo.value.cust_id, 1, 20, ...payload];
    try {
      const res: any = await network.request({
        cmdId: 310430,
        data: data,
        headerType: 2,
        paramType: 2,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        if (res.data && res.data.data) {
          this.bindBanklist = res.data.data;
        } else {
          this.bindBanklist = [];
        }
        return Promise.resolve(res.data);
      } else {
        this.bindBanklist = [];
        return Promise.reject(res.data);
      }
    } catch (error) {
      this.bindBanklist = [];
      return Promise.reject(error.data);
    }
  },
  /**
   * 检查提现密码是否正确
   * */
  async reqTestWithdrawPassword(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      ...payload,
      acct_pwd: utils.getp(payload.acct_pwd.trim()),
      cust_code: userInfo.value.cust_code
    };
    try {
      const res = await network.request({
        cmdId: 310136,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 添加 绑卡
   * open_addr,pay_org_id,pay_org_name,pay_name,pay_no,ifsc
   * telephone sort_v qr_code_address state pixKey pixType usdt_type
   * branch_number:分行好[支店番号] paynow_no:paynow账号
   * custContact:{mobile_phone,email,weixin}[个人资料信息]
   * */
  async reqBindCard(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      oper_code: userInfo.value.cust_code,
      state: 1,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 310400,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 添加 OTC 收款方式绑卡
   * pay_type收款方式1银行卡 2微信 3支付宝,custom_name姓名,pay_name银行名称,pay_name,pay_no微信号，支付宝账号 ,银行卡号
   * pay_addr开户行网点,code_addr收款二维码, isedit为真为修改
   * */
  async reqBindOtcCard(payload: any = {}, isEdit = false) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_name: userInfo.value.cust_name,
      pay_type: payload.pay_type || 1,
      custom_name: payload.custom_name || '',
      pay_name: payload.pay_name || '',
      pay_no: payload.pay_no || '',
      pay_addr: payload.pay_addr || '',
      code_addr: payload.code_addr || ''
    };
    try {
      const res = await network.request({
        cmdId: isEdit ? 370117 : 370116,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 添加 OTC 收款方式绑卡
   * pay_type收款方式1银行卡 2微信 3支付宝,custom_name姓名,pay_name银行名称,pay_name,pay_no微信号，支付宝账号 ,银行卡号
   * pay_addr开户行网点,code_addr收款二维码
   * */
  async reqOtcBindList(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_name: userInfo.value.cust_name,
      pay_type: payload.pay_type || -1, // -1 查全部
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10
    };
    try {
      const res = await network.request({
        cmdId: 370118,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取银行列表
   */
  async reqBankList(payload = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 321332,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 提现页面数据获取
   * */
  async reqWithdrawLimint(payload = []) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const tenantState = useTenantStore();
    // 发起请求
    let data = [tenantInfo.value.tid, userInfo.value.cust_id].concat(payload);
    try {
      const res: any = await network.request({
        cmdId: 310140,
        data: data,
        headerType: 2,
        paramType: 2,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        if (res.data.listFeel) {
          tenantState.tenantInfo.listFeel = res.data.listFeel;
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 期权项目专用提现 法币账户提现
   * */
  async reqAddWithdrawQiQuan(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data: any = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      acct_id: userInfo.value.acct_id,
      cust_code: userInfo.value.cust_code,
      oper_code: userInfo.value.cust_code,
      withdraw_type: 1,
      state: 1,
      chan_code: payload.chan_code || config.chanCode,
      remark: '审核中',
      pay_org_name: payload.pay_org_name,
      pay_no: payload.pay_no,
      pay_name: payload.pay_name,
      amount: payload.amount,
      pay_org_id: payload.pay_org_id,
      open_addr: payload.open_addr,
      pay_info_id: payload.pay_info_id,
      account_type: payload.account_type, // 1币币账户 2期货账户 3法币账户 4信托账户
      currency_name: payload.currency_name, // 币种
      currency: payload.area, // 法币国别
      rate: payload.huilv, // 法币汇率
      ...payload,
    };
    // 传了密码 才加密
    if (payload.acct_pwd) {
      data.acct_pwd = getp(payload.acct_pwd)
    }
    try {
      const res = await network.request({
        cmdId: 327044,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  /** UUID转账下级 gen_amount:金额
   * @param @requires payload.cust_id2
   * @param @requires payload.gen_amount
   * @param @requires payload.acct_pwd
   * @param payload.remark
   */
  async reqUUIDTransfer(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      tid: tenantInfo.value.tid,
      acct_id1: userInfo.value.acct_id,
      cust_id1: userInfo.value.cust_id,
      acct_name1: userInfo.value.cust_name,
      state: 1,
      oper_id: userInfo.value.cust_id,
      oper_code: userInfo.value.cust_code,
      chan_code: config.chanCode,
      ...payload,
      cust_id2: payload.cust_id2 || '',
      remark: payload.remark || '',
      acct_type: payload.acct_type || '',
      busi_type: payload.busi_type || 804,
      gen_amount: payload.gen_amount, // 厘
      acct_pwd: getp(payload.acct_pwd),
      acct_id2: payload.acct_id2 || '',
      acct_name2: payload.cust_name2 || ''
    };
    try {
      const params = {
        cmdId: 340600,
        data: data,
        paramType: 1
      };
      const res: any = await network.request(params);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取充值大类列表 mark 1线下 2线上
   * */
  async reqPayList(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = [tenantInfo.value.tid, userInfo.value.cust_id];
    payload.mark && (data[2] = payload.mark);
    try {
      const res: any = await network.request({
        cmdId: payload.mark ? 310141 : 310139,
        data: data,
        headerType: 2,
        paramType: 2,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        let off = [];
        let online = [];
        let quick = [];
        res.data && res.data.forEach(item => {
          if (item.mark === 1) {
            off.push(item);
          } else if (item.mark === 2) {
            online.push(item);
          } else if (item.mark === 3) {
            quick.push(item);
          }
        });
        this.offineList = off; // 线下支付大类列表
        this.onLineList = online; // 线上支付大类列表
        this.quickList = quick;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 一些配置 线下充值银行卡配置
  async reqOffineConfig(opt = 'OFFLINE_RECHARGE_LIMIT') {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      option_code: opt
    };
    try {
      const res = await network.request({
        cmdId: 370730,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 发起充币 - 期权的充币
   * */
  async reqAddPayOffline(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      bonusAmountPercentage: payload.bonus_amount_percentage || '',
      cust_code: userInfo.value.cust_code,
      oper_code: userInfo.value.cust_code,
      cust_id: userInfo.value.cust_id,
      acct_id: userInfo.value.acct_id,
      chan_code: payload.chan_code || config.chanCode,
      tid: tenantInfo.value.tid,
      tenant_pay_id: payload.tenant_pay_id,
      pay_org_id: payload.pay_org_id,
      pay_org_name: payload.pay_org_name,
      pay_name: payload.pay_name,
      pay_no: payload.pay_no,
      cust_org_name: payload.cust_org_name,
      cust_pay_name: payload.cust_pay_name,
      cust_pay_no: payload.cust_pay_no,
      numbers: payload.numbers,
      rate: payload.rate,
      pay_chan: payload.pay_chan,
      third_order_id: payload.third_order_id,
      remark: payload.remark,
      headPortrait: payload.headPortrait,
      state: 1, // 待付款
      ...payload,
      amount: num2Li(payload.amount)
    };
    delete data.bonus_amount_percentage;
    try {
      const res = await network.request({
        cmdId: 341400,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 发起充币 - 期权的充币 线上
   * */
  async reqAddPayOnline(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data: any = {
      tid: tenantInfo.value.tid,
      payEngName: payload.payEngName,
      acctPayLog: JSON.stringify({
        cust_code: userInfo.value.cust_code,
        cust_id: userInfo.value.cust_id,
        acct_id: userInfo.value.acct_id,
        balance_type_id: payload.acctPayLog.balance_type_id || 1,
        chan_code: payload.acctPayLog.chan_code || config.chanCode,
        sourcetype: payload.acctPayLog.sourcetype,
        deposit: num2Li(payload.amount),
        status: payload.acctPayLog.status || 1, // 待付款
        tid: tenantInfo.value.tid,
        success_time: new Date().getTime(),
        bonus_amount_percentage: payload.acctPayLog.bonus_amount_percentage,
        account_type: payload.acctPayLog.account_type, // 期权币币充值
        currency_name: payload.acctPayLog.currency_name // 期权 币的名称
      }),
      paramsJson: JSON.stringify({
        amount: num2Li(payload.amount, 100),
        cust_id: userInfo.value.cust_id,
        redirect_url: payload.paramsJson?.redirect_url ?? window.location.host
      })
    };
    payload.type !== undefined && (data.type = payload.type);
    try {
      const res = await network.request({
        cmdId: 383010,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取在线客服列表
   * chan_con 111111，第0位代表android渠道，第1位代表苹果渠道，第2位代表web渠道，第3位代表微信渠道，其他位暂未定义
   * */
  async reqServiceLists(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      state: 1,
      message_id: payload.message_id || 50,
      tid: tenantInfo.value.tid,
      proxy: tenantInfo.value.proxy,
      ...payload,
      chan_code: payload.chan_code || config.chanCode,
      language: payload.language || Number(get('languageNum') || 0),
      apply: payload.apply || undefined
    };
    userInfo.value.cust_id && (data.cust_id = userInfo.value.cust_id);
    try {
      const res = await network.request({
        cmdId: 380239,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取客服页面 - 帮助文档
   * tid=3&state=1&language=8&page_start=1&num_per_page=10
   * */
  async reqServiceHelps(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    let data = {
      state: 1,
      ...payload,
      tid: tenantInfo.value.tid,
      language: payload.language || Number(get('languageNum') || 0),
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10
    };
    try {
      const res = await network.request({
        cmdId: 323501,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 获取在线客服的token
   * */
  async customerGetTokenById(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tId: tenantInfo.value.tid,
      custId: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res: any = await network.request({
        cmdId: 314125,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        this.customerGetUrlQuery = res.data?.data ?? '';
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 退出登录
   * */
  async logout(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      ...payload
    };
    try {
      const res: any = await network.request({
        cmdId: 310635,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false,
        client_id: 'react_client',
        scope: 'read write',
        domain_addr: window.location.host,
        chan_code: data.chan_code || config.chanCode
      });
      if (res && res.type === 'success') {
        if (res.data && res.data.response_code === '9999') {
          this.clearUserinfo();
          // this.$reset();
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 刷新用户vip等级
   * */
  async reUserVip() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res: any = await network.request({
        cmdId: 3101401,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        if (res.data) {
          this.saveUser({ level_info: res.data.level_info, cust_level: res.data.cust_level });
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 修改登录密码
   * */
  async resetPassword(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      ...payload,
      mark: payload.mark || '1',
      ispwd: payload.ispwd || '1',
      password: getp(payload.password),
      ori_password: getp(payload.ori_password)
    };
    try {
      const res: any = await network.request({
        cmdId: 31011101,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        if (res.data && res.data.response_code === '9999') {
          this.clearUserinfo();
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 忘记密码
   * */
  async forgetPassword(payload: any = {}) {
    const { hotData } = useHot();
    // eslint-disable-next-line no-unused-vars
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      ...payload,
      password: getp(payload.password)
    };
    try {
      const res = await network.request({
        cmdId: 31011103,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 检验登录密码
   * */
  async yzLoginPwd(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      pwd: getp(payload.pwd)
    };
    try {
      const res = await network.request({
        cmdId: 3101402,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 是否修改过密码
   * */
  async ifRePassword(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      pwd_type: payload.pwd_type,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 313232,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 修改提现密码
   * */
  async resetWithdrawPassword(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ispwd: payload.ispwd,
      ...payload,
      acct_pwd: getp(payload.acct_pwd),
      ori_acct_pwd: getp(payload.ori_acct_pwd)
    };
    try {
      const res = await network.request({
        cmdId: 310122,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 获取联系方式
   * */
  async fetchContact() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = [tenantInfo.value.tid, userInfo.value.cust_id];
    try {
      const res = await network.request({
        cmdId: 310230,
        data: data,
        headerType: 2,
        paramType: 2,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 修改用户联系方式
   * @param payload.contact_name 姓名
   * @param payload.gender 性别 1:男 2:女
   * @param payload.contact_addr 联系地址
   * @param payload.company 公司
   * @param payload.home_phone 家用电话
   * @param payload.office_phone 办公电话
   * @param payload.mobile_phone 手机号码
   * @param payload.detail 详情
   * @param payload.email 电子邮箱
   * @param payload.qq QQ号码
   * @param payload.weixin 微信号码
   * @param payload.post_code 邮政编码
   * @param payload.post_addr 通信地址
   * @param payload.fax 传真
   */
  async reContact(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      contact_name: payload.contact_name || '',
      email: payload.email || '',
      weixin: payload.weixin || '',
      qq: payload.qq || '',
      mobile_phone: payload.mobile_phone || '',
      contact_addr: payload.contact_addr || '',
      facebook: payload.facebook || '',
      whatsApp: payload.whatsApp || '',
      mark: 1
    };
    try {
      const res = await network.request({
        cmdId: 310211,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        this.contact = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 修改你昵称
   * */
  async reNickName(payload: any = {}) {
    const RNEventInstance = RNEvent.getInstance();
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      nickname: payload.nickname
    };
    try {
      const res: any = await network.request({
        cmdId: 310113,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        if (res.data && res.data.response_code === '9999') {
          let user = getJSON('userInfo');
          user.nickname = payload.nickname;
          if (isApp()) {
            RNEventInstance.writeRecord('userInfo', JSON.stringify(user));
          } else {
            setJSON('userInfo', user);
          }
          this.userInfo = user;
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 检测用户名是否可注册
   */
  async checkRegister(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    const data = {
      cust_name: payload.cust_name,
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 310130,
        data: data
      });
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 量化交易 - vip等级
  async reqVIPList(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 327053,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 量化交易 - vip等级领取
  async getReward(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      receive_type: payload.receive_type,
      level: payload.level,
      amount: payload.amount,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 327054,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // vip等级页面领取奖励和俸禄
  async getRewardMoney(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res: any = await network.request({
        cmdId: 374001,
        data: data
        // headerType: 2,
        // paramType: 0,
        // isApiV2: false
      });
      if (res?.data?.code === '1002') {
        return Promise.resolve(res.data);
      }
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // vip等级详情
  async getVipDetail(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      option_code: 'VIP_ACTIVITY_RULE_CONFIG',
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 370730,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 查看用户是否有创建虚拟会员权限
   */
  async reqIsCreateFict() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const data = [tenantInfo.value.tid, userInfo.value.cust_id];
    this.allowCreateMuc = false;
    try {
      const res: any = await network.request({
        cmdId: 310145,
        data: data,
        headerType: 2,
        paramType: 2
      });
      if (res && res.type === 'success') {
        this.isCreateFict = Number(res.data.response_code) === 9999;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 量化交易 - 邀请好友
  async getFirends(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 327055,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        let resulate: any = res.data || [];
        let newArr = [];
        resulate.forEach(item => {
          // 分享领工资
          if (item.type === 106) {
            let shareIt = [];
            let standara = item.standara;
            let amount = standara.record;
            let arr = [];
            for (let z = 1; z < 11; z++) {
              let list: any = {};
              let stan2 = 'standara' + z;
              let standara_name = 'standara_name' + z;
              let icon_url = 'icon_url' + z;
              let explains = 'explains' + z;
              if (standara[stan2]) {
                let listArr2 = standara[stan2].split(':');
                list.fraction = Number(listArr2[0]); // 达标
                list.reward = Number(listArr2[1]); // 奖金
                list.standara_name = standara[standara_name];
                list.icon_url = standara[icon_url];
                list.explains = standara[explains];
                if (list.fraction !== 0) {
                  arr.push(list.fraction);
                  list.isReceive = 0;
                  list.percentage = 0;
                  list.startTime = item.eff_time;
                  list.endTime = item.exp_time;
                  shareIt.push(list);
                }
              }
            }
            for (let x = 0; x < arr.length; x++) {
              let item = arr[x];
              if (item <= amount) {
                shareIt[x].isReceive = 2;
                shareIt[x].percentage = 100;
              }
              if (x === 0) {
                if (amount === 0) {
                  shareIt[x].percentage = 0;
                } else {
                  if (item === amount) shareIt[x].isReceive = 2;
                  shareIt[x].percentage = parseInt(String((amount / item) * 100));
                  if (shareIt[x].percentage > 100) shareIt[x].percentage = 100;
                }
              } else if (item > amount && amount > arr[x - 1]) {
                shareIt[x - 1].isReceive = 2;
                shareIt[x].percentage = parseInt(String(((amount - arr[x - 1]) / (item - arr[x - 1])) * 100));
              }
              shareIt[x].completed = amount < 0 ? 0 : amount;
              if (standara.receive_amount) {
                standara.receive_amount.forEach(item => {
                  if (Number(item.receive_level) - 1 === x) {
                    shareIt[x].isReceive = 1; // 已领取
                  }
                });
              }
            }
            item.standara = shareIt;
            newArr.push(item);
          }
        });
        if (newArr && newArr.length > 0) {
          newArr.sort((a, b) => a.sort_v - b.sort_v);
        }
        return Promise.resolve(newArr);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 量化交易 - 领取邀请好友奖励
  async RecriveRescueMoney(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      receive_level: payload.amount,
      type: payload.type,
      amount: payload.reward.toFixed(3) * 1000
    };
    try {
      const res = await network.request({
        cmdId: 327056,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 获取客户详细信息
   */
  async getResponseCode() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      partner_id: userInfo.value.partner.partner_id
    };
    try {
      const res = await network.request({
        cmdId: 311915,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 获取我的分享 - 佣金
   */
  async reqMyShareRebate(payload: any = {}) {
    if (payload.is_clear) {
      this.myShareRebate = {};
    }
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 324459,
        data: data
      });
      if (res && res.type === 'success') {
        this.myShareRebate = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 我的分享 - 邀请好友页面
  async reqMyShare(payload: any = {}) {
    if (payload.is_clear) {
      this.myShareList = {};
    }
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 311935,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        this.myShareList = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取邀请码
   */
  async reqInvicode(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      link_addr: payload.link_addr,
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 313032,
        data: data,
        headerType: 1
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取虚拟货币和法币 OTC用的
   */
  async reqOTCCurrency(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      option_code: 'OTC_INFO'
    };
    try {
      const res: any = await network.request({
        cmdId: 370730,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        if (res.data) {
          this.OTCbitLists = (res.data.value || '').split(',');
          this.OTCFBLists = (res.data.value2 || '').split(',');
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取OTC广告列表 OTC用的
   */
  async reqOTCAdList(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid
    };
    try {
      const res: any = await network.request({
        cmdId: 370001,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        res.data.trans_types = payload?.trans_types || '';
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取OTC购买 OTC用的 具体传参看调用的地方
   */
  async reqOTCAdBuy(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      oper_code: userInfo.value.cust_name
    };
    try {
      const res = await network.request({
        cmdId: 3700044,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 查询otc广告订单记录
  async reqOTCAdBillList(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid
    };
    try {
      const res: any = await network.request({
        cmdId: 370027,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        res.data.sendstate = payload.state; // 回来的数据状态判断
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 取消otc广告订单
  async reqOTCAdBillCancel(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 370007,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // otc订单详细信息 通过订单id
  async reqOTCOrderInfo(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      id: payload.id
    };
    try {
      const res = await network.request({
        cmdId: 340323,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 完成otc广告订单
  async reqOTCAdBillDone(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      oper_code: userInfo.value.cust_code
    };
    try {
      const res = await network.request({
        cmdId: 370028,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 查询otc货币数量
  async reqOTCCount(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 370011,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 查询otc货币数量-期权专用 tid  cust_id currency balance_type_id
  async reqQiOTCCount(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      currency: payload.currency,
      balance_type_id: payload.balance_type_id
    };
    try {
      const res = await network.request({
        cmdId: 340322,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 增加otc广告
  async reqOTCAdAdd(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_name: userInfo.value.cust_name,
      cust_id: userInfo.value.cust_id,
      nick_name: userInfo.value.nickname,
      oper_code: userInfo.value.cust_name
    };
    try {
      const res = await network.request({
        cmdId: 370002,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 更改otc广告状态
  async reqOTCAdState(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      oper_code: userInfo.value.cust_code
    };
    try {
      const res = await network.request({
        cmdId: 370006,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 编辑otc广告
  async reqOTCAdEdit(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_name: userInfo.value.cust_name,
      cust_id: userInfo.value.cust_id,
      nick_name: userInfo.value.nickname,
      oper_code: userInfo.value.cust_name
    };
    try {
      const res = await network.request({
        cmdId: 370003,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // otc我的页面数据 包括 成交数量 认证状态 广告主认证状态
  async reqOTCMyInfo(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_name: payload.cust_name || userInfo.value.cust_name
    };
    try {
      const res = await network.request({
        cmdId: 370119,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // otc广告主认证申请 退还保证金
  // trans_type 1 申请，2 退还
  async reqOTCBZJ(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_name: userInfo.value.cust_name,
      cust_id: userInfo.value.cust_id,
      nick_name: userInfo.value.nickname,
      trans_type: payload.trans_type || 1, // 默认申请
      country: payload.country || ''
    };
    try {
      const res = await network.request({
        cmdId: 370114,
        data: data,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 我的佣金（多种模式）
  async reqCommissionList(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      num_per_page: 10,
      ...payload
    };
    try {
      const res: any = await network.request({
        cmdId: 382234,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        this.commissionList = res.data.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  async reqMyCommission(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    if (payload.is_clear) {
      this.myCommissionlist = [];
    }
    // 发起请求
    const data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 311936,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        this.myCommissionlist = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  /**
   * 请求优惠活动左边的列表
   * @param context
   * @param payload
   */
  async reqActivityListLeft(payload: any = {}) {
    const { hotData } = useHot();
    // eslint-disable-next-line no-unused-vars
    const { tenantInfo, userInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      proxy: tenantInfo.value.proxy,
      ...payload
    };

    if (payload.chan_con !== undefined) {
      data.chan_con = payload.chan_con;
    }
    // // 1 棋牌；2 网赚；3 综合彩票；3 老版彩票； (apply后端设置默认为1，为可选参数)
    if (payload.apply) {
      data.apply = payload.apply;
    }
    if (payload.type) {
      data.type = payload.type;
    }
    data.language = payload.language || Number(window.localStorage.getItem('languageNum') || '0');
    // type 后台 MWEB IOS ANDROID PC
    let sendPayLoad = {
      headerType: 2,
      cmdId: 380237,
      data: data,
      paramType: 0
    };

    try {
      const res = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        this.activityListLeft = res.data;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  /**
   * 根据优惠活动查询详情的
   * @param context
   * @param payload
   */
  async reqActivityDetail(payload: any = {}) {
    const { hotData } = useHot();
    // eslint-disable-next-line no-unused-vars
    const { tenantInfo, userInfo } = hotData;

    const data = {
      tid: tenantInfo.value.tid,
      proxy: tenantInfo.value.proxy,
      ...payload
    };
    if (payload.cat) {
      data.cat = payload.cat;
    }

    data.chan_con = payload.chan_con;
    // // 1 棋牌；2 网赚；3 综合彩票；3 老版彩票； (apply后端设置默认为1，为可选参数)
    if (payload.apply) {
      data.apply = payload.apply;
    }
    if (payload.type) {
      data.type = payload.type;
    }
    data.language = payload.language || Number(window.localStorage.getItem('languageNum') || '0');
    let sendPayLoad = {
      cmdId: 380238,
      data: data,
      paramType: 0
    };
    try {
      const res = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        // 该详情的数据有点大，暂不存在vux中
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 获取会员任务 */
  async reqUserTaskList() {
    const { hotData, activeConfiguration } = useHot();
    const { tenantInfo, userInfo, isLogined } = hotData;
    if (!activeConfiguration.value['35']) {
      return Promise.reject({});
    }
    const data = {
      tid: tenantInfo.value.tid
    } as any;
    if (isLogined.value) {
      data.cust_id = userInfo.value.cust_id;
    }
    let sendPayLoad = {
      cmdId: isLogined.value ? 395010 : 395014,
      data: data
    };
    try {
      const res = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        this.setUserTaskList(res.data);
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 获取会员任务 */
  async reqRescueMoney(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      receive_level: payload.amount,
      type: payload.type,
      amount: payload.reward.toFixed(3) * 1000
    };
    try {
      const res = await network.request({
        cmdId: 394012,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 领取会员任务奖励 */
  async reqTakeUserTaskGift(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      custId: userInfo.value.cust_id,
      ...payload,
      custTaskId: payload.custTaskId,
      standaraNumber: payload.standaraNumber,
      standaraType: payload.standaraType
    };
    try {
      const res = await network.request({
        cmdId: 395011,
        data: data,
        paramType: 1
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 领取活动奖金 */
  async reqActivityBonus() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 377209,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 活动奖金领取接口 */
  async reqBonusPickup(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      task_id: payload.task_id,
      id: payload.id
    };
    try {
      const res = await network.request({
        cmdId: 377210,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 期权-代理申请页面 */
  async reqagentSQdata(payload = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 390202,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 期权-代理申请页面-提交 */
  async reqagentIsPost(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      rebate_mode: payload.rebate_mode,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 390203,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 我的分享 - 邀请好友页面
  async reqAgentMyShare(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 311947,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 代理系统 - 佣金数据
  async reqAgentYJ(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 311946,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 代理系统 - 代理报表
  // start_time end_time partner_name 代理名称
  async reqAgencyReport(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload,
      start_time: payload.start_time,
      end_time: payload.end_time,
      partner_name: payload.partner_name || ''
    };
    try {
      const res = await network.request({
        cmdId: 380891,
        data: data,
        headerType: 2,
        paramType: 0
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 代理系统 - 获取代理商列表
   * @param @requires payload.start_page
   * @param @requires payload.num_per_page
   */
  async reqQiAgentList(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      ...payload,
      depth: payload.depth || 1,
      level: payload.level || 1
    };
    if (payload.partner_name) data.partner_name = payload.partner_name;
    try {
      const params = {
        cmdId: 311945,
        data,
        headerType: 2,
        paramType: 0
      };
      const res: any = await network.request(params);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 免息借呗可借、待还额度查询
   */
  async reqBorrowInfo() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    let sendPayLoad = {
      cmdId: 381431,
      data: data
    };
    try {
      const res = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 我要借款查询
   */
  async reqBorrowLoanInfo() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    let sendPayLoad = {
      cmdId: 381432,
      data: data
    };
    try {
      const res = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 借款
   * @param payload.options 更多参数 JSON
   */
  async reqBorrowLoan(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      amount: payload.amount
    };
    let sendPayLoad = {
      cmdId: 381420,
      data: data
    };
    try {
      const res = await network.request(sendPayLoad);

      if (res) {
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 我要还款查询
   * @param payload.options 更多参数 JSON
   */
  async reqBorrowPaybackInfo() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    let sendPayLoad = {
      cmdId: 381433,
      data: data
    };
    try {
      const res = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 还款
   * @param payload.options 更多参数 JSON
   */
  async reqBorrowPayback(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      amount: Number(payload.amount)
    };
    let sendPayLoad = {
      cmdId: 381421,
      data: data
    };
    try {
      const res = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 借呗额度
   * @param payload.options 更多参数 JSON
   */
  async reqBorrowQuota() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    let sendPayLoad = {
      cmdId: 381131,
      data: data
    };
    try {
      const res = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 借呗记录
   * @param payload.options 更多参数 JSON
   */
  async reqBorrowRecord(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      cust_name: userInfo.value.cust_name,
      page_start: payload.page_start,
      num_per_page: payload.num_per_page
    };
    let sendPayLoad = {
      cmdId: 381430,
      data: data
    };
    try {
      const res = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  async reqBorrowDescription() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      type: '108'
    };
    let sendPayLoad = {
      cmdId: 370601,
      data: data
    };
    try {
      const res: any = await network.request(sendPayLoad);
      if (res && res.type === 'success') {
        let borrowTypearr = [
          {
            type: 'level_reward',
            name: '晋级奖励'
          },
          {
            type: 'week_reward',
            name: '周俸禄'
          },
          {
            type: 'month_reward',
            name: '月俸禄'
          },
          {
            type: 'charge_reward',
            name: '充值大礼包'
          },
          {
            type: 'play_reward',
            name: '天天得意金'
          },
          {
            type: 'loss_reward',
            name: '亏损救援金'
          },
          {
            type: 'game_reward',
            name: '游戏任务'
          },
          {
            type: 'cust_reward',
            name: '会员任务'
          },
          {
            type: 'virtual_reward',
            name: '虚拟币任务'
          },
          {
            type: 'lucky_reward',
            name: '幸运注单'
          },
          {
            type: 'answer_reward',
            name: '答题活动'
          },
          {
            type: 'sign_reward',
            name: '签到奖金'
          },
          {
            type: 'red_reward',
            name: '红包雨'
          },
          {
            type: 'share_reward',
            name: '分享领工资'
          },
          {
            type: 'balance',
            name: '余额'
          }
        ];
        let borrowType = [];
        if (res.data) {
          for (let i = 0; i < borrowTypearr.length; i++) {
            if (res.data[borrowTypearr[i].type]) {
              borrowType.push(borrowTypearr[i].name);
            }
          }
          this.setBorrowInfo({ type: borrowType, date: res.data.borrow_day });
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 功能是否显示
   * @param @requires payload.type 功能类型
   * @param payload.options 更多参数 JSON
   */
  async reqFeatures(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      type: payload.type
    };
    let sendPayLoad = {
      cmdId: 370601,
      data: data
    };
    try {
      const res = await network.request(sendPayLoad);
      if (res) {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  /**
   * 游客登录 指数虚拟交易 虚拟账户
   */
  async YKlogin(data) {
    const RNEventInstance = RNEvent.getInstance();
    const tenantState = useTenantStore();
    data = {
      ...data,
      chan_code: data.chan_code || config.chanCode,
      client_id: 'react_client',
      domain_addr: window.location.host,
      grant_type: 'password',
      scope: 'read write',
      tid: tenantState.tenantInfo.tid
    };
    try {
      const res = await network.request({
        cmdId: 420006,
        data: data,
        headerType: 1,
        paramType: 1,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        // remark: 'SYS_GENERATE' 表示虚拟会员 指数虚拟交易
        this.userInfo = res.data;
        if (isApp()) {
          RNEventInstance.writeRecord('userInfo', JSON.stringify(res.data));
        }
        setJSON('userInfo', res.data);
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 盲盒 - 规则说明
   */
  async reqMHResult(payload) {
    const {
      hotData: { tenantInfo }
    } = useHot();
    let data = {
      ...payload,
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 420009,
        data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 盲盒 - 盲盒记录 个人记录传 cust_name
   */
  async reqMHOrderList(payload) {
    const {
      hotData: { tenantInfo }
    } = useHot();
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10
    };
    try {
      const res = await network.request({
        cmdId: 420020,
        data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 盲盒 - 开启盲盒 balance_type_id 账户ID，amount开启盲盒的钱 0 表示免费，trade_medium钱的单位[USDT或BTC]
  async reqOpenMH(payload) {
    const {
      hotData: { tenantInfo, userInfo }
    } = useHot();
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      balance_type_id: payload.balance_type_id,
      amount: payload.amount,
      trade_medium: payload.trade_medium
    };
    try {
      const res = await network.request({
        cmdId: 420021,
        data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 盲盒 - 当前盲盒信息 开启是否免费 开启次数 开启钱的单位等
  async reqMHInfo(payload) {
    const {
      hotData: { tenantInfo, userInfo }
    } = useHot();
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 420022,
        data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取本地国别列表 - json
  async reqLocalCountry(payload: any = {}) {
    try {
      const res = await network.getContent({
        url: payload.url
      });
      if (res && res.status === 200) {
        this.localCountry = res.data || [];
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 通过cust_id获取用户信息
  async getUserInfoByCustId(payload: any = {}) {
    const { hotData: { tenantInfo } } = useHot();
    let data = [tenantInfo.value.tid, ...payload];
    const para:any = {
      cmdId: 310131,
      data,
      paramType: 2,
      isApiV2: false
    }
    if (isApp()) {
      para.headerType = 1
    }
    try {
      const res = await network.request(para);
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 设置用户聊天室昵称
  async setUserChatRoomNickname(payload: any = {}) {
    const { hotData: { tenantInfo, userInfo } } = useHot();
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 325318,
        data,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  }
};
