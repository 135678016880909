/*
 * @Author: castic
 * @Date: 2024-12-11 15:26:39
 * @LastEditors: castic
 * @LastEditTime: 2024-12-23 18:54:08
 * @Describe: 
 */
import {createPinia} from "pinia"
import {toRaw} from 'vue'


const setStorage = (key: string, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value))
}
// 获取本地数据
const getStorage = (key: string) => {
  const data = sessionStorage.getItem(key)
  return data ? JSON.parse(data) : {};
}

const piniaPlugin = (context: any) => {
  const { store } = context;
  const SaveKey = `__pinia__${store.$id}`
  // $subscribe state值发生变化时会执行传入的回调
  store.$subscribe(() => {
      // 每次修改值的时候更新localStorage数据
    // console.log('storestore:', store)
    setStorage(`${SaveKey}`, toRaw(store.$state))
  })
  // 每次构建项目的时候从本地存储取值
  const data = getStorage(`${SaveKey}`)
  // console.log('storestore--->data:', data)
  return {
      ...data
  }
}

const piniaStore = createPinia()
// piniaStore.use(piniaPlugin)

export { piniaStore }



// export const piniaStore = createPinia()
